import React from 'react'
import { EmojiType } from '@types'
import {
  hand,
  blockade,
  branch,
  leaf,
  stop,
  caddie,
  camera,
  park,
  pointer,
  selfie,
  tent,
  toilet,
  bee
} from './types'
import cn from 'classnames'

const Emoji = ({
  type,
  className
}: {
  type: EmojiType
  className?: string
}) => {
  const typesOfEmoji: { [key in EmojiType]: React.ElementType } = {
    hand,
    blockade,
    branch,
    leaf,
    stop,
    caddie,
    camera,
    park,
    pointer,
    selfie,
    tent,
    toilet,
    bee
  }

  const Cmp = typesOfEmoji[type]

  if (!Cmp) return null

  return (
    <span className={cn('pledge-emoji', className)} role='img'>
      <h1>
        <Cmp />
      </h1>
    </span>
  )
}

export default Emoji
