import React from 'react'
import { ImageAssetType } from '@types'
import Img from 'gatsby-image'

type Props = {
  cloud: ImageAssetType
  className?: string
}

const Cloud = ({
  cloud,
  className = 'pledge-background__cloud w-100'
}: Props) => {
  return (
    <div className={className}>
      <Img
        className='h-100 w-100'
        imgStyle={{ objectFit: 'contain' }}
        fluid={cloud.fluid}
        alt='cloud'
      />
    </div>
  )
}

export default Cloud
