import { useLocation } from '@reach/router'
import { Languages } from '@types'

export const useGetLocale = (): Languages => {
  const p = useLocation().pathname.trim()
  const lang = p.slice(0, 3)
  switch (lang) {
    case '/de':
      return 'de'
    case '/es':
      return 'es'
    case '/fr':
      return 'fr'
    default:
      return 'en'
  }
}
