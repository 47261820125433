import { AmbienceSound } from '@cmp/site/AudioPlayer'
import Background from '@cmp/site/Background'
import Badge from '@cmp/site/Badge'
import CircleButton from '@cmp/site/CircleButton'
import Game from '@cmp/site/Game'
import LoadingState from '@cmp/site/LoadingState'
import Pointer from '@cmp/site/Pointer'
import Speaker from '@cmp/site/Speaker'
import { steps } from '@data'
import { useGetAssets } from '@hooks/useGetAssets'
import { useGetLocale } from '@hooks/useGetLocale'
import { ButtonDep as Button, SEO } from '@islandsstofa/react'
import { usePledge } from '@store'
import cn from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import translations from '../data/translations.json'

const PledgeBackground = () => {
  const { images, video, sounds } = useGetAssets()
  const ambience = sounds.find(sound => sound.name === 'ambience')
  const INITIAL_LOAD = 5000
  const [isLoading, setIsLoading] = useState(true)
  const language = useGetLocale()
  const t = translations[language]

  // We set an inital load time so that the Spline 3D graphics loads only at start but loads seemlessly at the end.
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, INITIAL_LOAD)
  }, [])

  const currentStep = usePledge(state => state.currentStep)
  const lastStep = currentStep === steps.length - 1
  const startedGame = currentStep > 0
  const success = sounds.find(sound => sound.name === 'success')
  const successAudioRef = useRef<HTMLAudioElement | null>(null)

  const goBackUrl =
    language === 'de'
      ? 'https://visiticeland.com/de'
      : language === 'es'
      ? 'https://visiticeland.com/es'
      : language === 'fr'
      ? 'https://visiticeland.com/fr'
      : 'https://visiticeland.com'

  useEffect(() => {
    if (lastStep) {
      if (successAudioRef.current) {
        setTimeout(() => {
          successAudioRef?.current?.play()
        }, 2500)
      }
    }
  }, [currentStep])

  return (
    <>
      <Helmet>
        <title>{t.title} ✋️ Visit Iceland</title>
        <SEO
          title={t.title}
          description={t.seo_description}
          image={{ url: '/assets/pledge/nightTime.jpg' }}
        />
      </Helmet>
      <div className='pledge'>
        <Background images={images} video={video} />
        <div
          className={cn('pledge-badge__container', {
            'pledge-badge__container--show': lastStep
          })}
        >
          <h3 className='text-center'>{t.spin}</h3>
          <Badge />
          <audio ref={successAudioRef} src={success?.url} />
        </div>
        <Game language={language} />
        <div
          className={cn('pledge__button-wrap', {
            'pledge__button-wrap--show': currentStep >= 0,
            'pledge__button-wrap--moveUp': startedGame,
            'pledge__button-wrap--hide': lastStep
          })}
        >
          <Pointer />
          <CircleButton text={t.continue} />
        </div>
      </div>
      <Speaker />
      <div
        className={cn('pledge__back-home-btn container', {
          'pledge__back-home-btn--show': lastStep
        })}
      >
        <a className='d-flex pledge__back-home-btn__button' href={goBackUrl}>
          <Button
            icon='arrowRight'
            type='secondary'
            label={t.go_back}
            className='d-flex flex-row-reverse'
          />
        </a>
      </div>
      {ambience && (
        <AmbienceSound audioFile={ambience.url} play={startedGame} />
      )}
      {isLoading && (
        <LoadingState images={images} text={t.creating} language={language} />
      )}
    </>
  )
}

export default PledgeBackground
