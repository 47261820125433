import create from 'zustand'
import { StepType } from '@types'
import { steps } from '@data'

interface IPledge {
  currentStep: number
  toggleStep: () => void
  steps: StepType[]
}

const usePledge = create<IPledge>(set => ({
  currentStep: 0,
  toggleStep: () =>
    set(state => ({
      currentStep:
        state.currentStep < steps.length - 1
          ? state.currentStep + 1
          : state.currentStep
    })),
  steps
}))

export { usePledge }
