import React, { useRef, useEffect } from 'react'
import { useGetAssets } from '@hooks/useGetAssets'

type Props = {
  audioFile: string
  play?: boolean
}

// this component essentially
// loops an audio file and
// fades between each sound so that
// it sounds like an endless sound
const AudioPlayer = ({ play = false }: Props) => {
  const audioRef = useRef<HTMLAudioElement>(null)
  const audioRef2 = useRef<HTMLAudioElement>(null)

  const { sounds } = useGetAssets()

  const ambience = sounds.find(sound => sound.name === 'ambience')

  useEffect(() => {
    const BUFFER = 4
    const loop = (current: HTMLAudioElement, next: HTMLAudioElement) => {
      if (current.currentTime > current.duration - BUFFER) {
        // start the next sound
        if (next.paused) {
          next.play()
        }
      }
    }

    if (audioRef && audioRef.current && audioRef2 && audioRef2.current) {
      if (play) {
        audioRef.current.play()
        audioRef.current.addEventListener('timeupdate', () => {
          const firstAudio = audioRef.current
          const secondAudio = audioRef2.current
          if (firstAudio && secondAudio) {
            loop(firstAudio, secondAudio)
          }
        })
        audioRef2.current.addEventListener('timeupdate', () => {
          const firstAudio = audioRef.current
          const secondAudio = audioRef2.current
          if (firstAudio && secondAudio) {
            loop(secondAudio, firstAudio)
          }
        })
      } else {
        audioRef.current.pause()
        audioRef2.current.pause()
      }
    }
  }, [play])

  return (
    <>
      <audio ref={audioRef} src={ambience?.url} />
      <audio ref={audioRef2} src={ambience?.url} />
    </>
  )
}

export default AudioPlayer
