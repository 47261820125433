import React, { useEffect } from 'react'

type Props = {
  text: string
}

const Canvas = ({ text }: Props) => {
  useEffect(() => {
    const canvas = document.getElementById('canv') as HTMLCanvasElement

    if (canvas) {
      const context = canvas.getContext('2d')
      if (context) {
        context.font = '500 20px Neausa'
        context.fillStyle = 'white'
        context.textAlign = 'center'

        const string = text.toUpperCase() + ' '
        const angle = Math.PI * 2
        const radius = text.length > 25 ? 8 : 7 // 7 for english 8 for german

        context.translate(70, 70)
        context.rotate((-6 * angle) / 2)

        for (let i = 0; i < string.length; i++) {
          context.rotate(angle / string.length)
          context.save()
          context.translate(0, -6 * radius)
          context.fillText(string[i], 0, 0)
          context.restore()
        }
      }
    }
  }, [])
  return (
    <div className='pledge-circle-button__circle d-flex'>
      <canvas width='140' height='140' id='canv'></canvas>
    </div>
  )
}

export default React.memo(Canvas)
