import React, { useEffect, useState, useRef } from 'react'
import { useGetAssets } from '@hooks/useGetAssets'
import { Icon } from '@islandsstofa/react'
import cn from 'classnames'
import { usePledge } from '@store'

const Button = () => {
  const currentStep = usePledge(state => state.currentStep)
  const toggleStep = usePledge(state => state.toggleStep)
  const steps = usePledge(state => state.steps)

  const { sounds } = useGetAssets()

  const beep = sounds.find(sound => sound.name === 'check-sound')

  const audioRef = useRef<HTMLAudioElement | null>(null)

  const [checkboxState, setCheckboxState] = useState<
    'checked' | 'unchecked' | 'default'
  >('default')

  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (currentStep > 0 || currentStep === steps.length - 1) {
      setCheckboxState('checked')
      setTimeout(() => setCheckboxState('unchecked'), 500)
    }
  }, [currentStep])

  useEffect(() => {
    if (disabled) {
      setTimeout(() => {
        setDisabled(false)
      }, 1000)
    }
  }, [disabled])

  return (
    <>
      <audio ref={audioRef} src={beep?.url}></audio>
      <button
        disabled={disabled}
        onClick={() => {
          toggleStep()
          setDisabled(true)
          if (audioRef.current) {
            audioRef.current.play()
          }
        }}
      >
        <div
          className={cn('pledge-circle-button__checkbox', {
            'pledge-circle-button__checkbox--checked':
              checkboxState === 'checked' || currentStep === steps.length - 1
          })}
        >
          <div
            className={cn('pledge-circle-button__checkbox__fill', {
              'pledge-circle-button__checkbox__fill--checked':
                checkboxState === 'checked' || currentStep === steps.length - 1,
              'pledge-circle-button__checkbox__fill--unchecked':
                checkboxState === 'unchecked'
            })}
          >
            <Icon className='h-100 w-100' type='check' />
          </div>
        </div>
      </button>
    </>
  )
}

export default Button
