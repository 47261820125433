import React from 'react'

const SpeakerMuted = () => {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M38.38 17.1561C37.9643 16.781 37.3236 16.8154 36.9485 17.2311L34.6614 19.7737V15.3759C34.6614 13.916 33.7956 13.2611 32.9379 13.2611C32.4371 13.2611 31.9363 13.4719 31.4456 13.8876L23.1366 20.9497H19.4545C17.7777 20.9497 16.4131 22.3142 16.4131 23.9911V32.1014C16.4131 33.7782 17.7777 35.1428 19.4545 35.1428H20.8271L18.7002 37.507C18.3251 37.9226 18.3596 38.5633 18.7752 38.9384C18.9699 39.1128 19.2112 39.198 19.4524 39.198C19.7302 39.198 20.006 39.0844 20.2067 38.8614L23.3738 35.3435L31.4456 42.2049C31.9343 42.6205 32.4371 42.8314 32.9379 42.8314C33.4894 42.8314 33.9841 42.5638 34.2964 42.0974C34.5377 41.7345 34.6614 41.2702 34.6614 40.7187V22.8029L38.457 18.5855C38.8321 18.1699 38.7976 17.5291 38.382 17.154L38.38 17.1561ZM32.6338 15.5401V22.0263L24.5234 31.0369V22.4318L32.6338 15.5381V15.5401ZM18.4407 32.1034V23.9931C18.4407 23.4335 18.8949 22.9793 19.4545 22.9793H22.4958V33.1172H19.4545C18.8949 33.1172 18.4407 32.6631 18.4407 32.1034ZM32.6338 40.5564L24.7302 33.8391L32.6338 25.0576V40.5564Z'
        fill='white'
      />
      <circle cx='28' cy='28' r='27.5' stroke='white' />
    </svg>
  )
}

const SpeakerOn = () => {
  return (
    <svg
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='28' cy='28' r='27.5' stroke='white' />
      <path
        d='M35.6713 38.2501C35.3668 38.2501 35.0644 38.1178 34.8565 37.8637C34.4911 37.4143 34.5583 36.7528 35.0077 36.3874C37.2274 34.5835 38.5 31.9081 38.5 29.0521C38.5 26.1961 37.2274 23.5228 35.0098 21.7168C34.5604 21.3514 34.4911 20.6899 34.8586 20.2405C35.2261 19.7911 35.8855 19.7218 36.3349 20.0893C39.046 22.2943 40.6 25.5619 40.6 29.0542C40.6 32.5465 39.046 35.812 36.3349 38.0191C36.1396 38.1766 35.9065 38.2543 35.6734 38.2543L35.6713 38.2501Z'
        fill='white'
      />
      <path
        d='M39.6444 43.1389C39.3399 43.1389 39.0375 43.0066 38.8296 42.7525C38.4642 42.3031 38.5314 41.6416 38.9808 41.2762C42.6789 38.269 44.7999 33.8128 44.7999 29.0521C44.7999 24.2914 42.6789 19.8352 38.9808 16.828C38.5314 16.4626 38.4621 15.8011 38.8296 15.3517C39.1971 14.9023 39.8565 14.833 40.3059 15.2005C44.4954 18.6088 46.8999 23.6593 46.8999 29.0542C46.8999 34.4491 44.4975 39.4996 40.3059 42.9079C40.1106 43.0654 39.8775 43.1431 39.6444 43.1431V43.1389Z'
        fill='white'
      />
      <path
        d='M31.7003 33.3592C31.3958 33.3592 31.0934 33.2269 30.8855 32.9728C30.5201 32.5234 30.5873 31.8619 31.0367 31.4965C31.7759 30.8938 32.2001 30.0034 32.2001 29.0521C32.2001 28.1008 31.7759 27.2083 31.0367 26.6077C30.5873 26.2423 30.518 25.5808 30.8855 25.1314C31.253 24.682 31.9124 24.6127 32.3618 24.9802C33.5945 25.9819 34.3001 27.4666 34.3001 29.0542C34.3001 30.6418 33.5924 32.1265 32.3618 33.1282C32.1665 33.2857 31.9334 33.3634 31.7003 33.3634V33.3592Z'
        fill='white'
      />
      <path
        d='M24.115 13.7347C23.5963 13.7347 23.0776 13.9531 22.5694 14.3836L13.9636 21.6979H10.15C8.4133 21.6979 7 23.1112 7 24.8479V33.2479C7 34.9846 8.4133 36.3979 10.15 36.3979H13.9636L22.5694 43.7122C23.0755 44.1427 23.5963 44.3611 24.115 44.3611C24.6862 44.3611 25.1986 44.0839 25.522 43.6009C25.7719 43.225 25.9 42.7441 25.9 42.1729V15.9229C25.9 14.4109 25.0033 13.7326 24.115 13.7326V13.7347ZM9.1 33.25V24.85C9.1 24.2704 9.5704 23.8 10.15 23.8H13.3V34.3H10.15C9.5704 34.3 9.1 33.8296 9.1 33.25ZM23.8 42.0049L15.4 34.8649V23.2351L23.8 16.0951V42.0049Z'
        fill='white'
      />
    </svg>
  )
}

export { SpeakerMuted, SpeakerOn }
