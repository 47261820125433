import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import { Languages, StepType } from '@types'
import { usePledge } from '@store'
import EmojiCloud from '../EmojiCloud'

type Props = {
  language: Languages
}

const Game = ({ language = 'en' }: Props) => {
  const currentStep = usePledge(state => state.currentStep)
  const steps = usePledge(state => state.steps)

  const [leaving, setLeaving] = useState(false)
  const [content, setContent] = useState<StepType>(steps[0])

  const lastStep = currentStep === steps.length - 1

  useEffect(() => {
    setLeaving(true)
    setTimeout(() => {
      setLeaving(false)
      setContent(steps[currentStep])
    }, 500)
  }, [currentStep])

  const Level: React.FC = ({ children }) => {
    return (
      <div
        className={cn(
          'pledge-game__level container text-center position-relative',
          {
            'pledge-game__level--enter': currentStep > 0 && !lastStep,
            'pledge-game__level--enterUp': lastStep,
            'pledge-game__level--tighter': content.endgame,
            'pledge-game__level--leave': leaving && currentStep > 0,
            'pledge-game__level--larger-font': content.largerText,
            'pledge-game__level--wider': content.startgame || content.longtext
          }
        )}
      >
        {children}
      </div>
    )
  }

  const emojis = {
    caddie: '🚗',
    hand: '✋',
    blockade: '🚧',
    branch: '🌿',
    leaf: '🍃',
    stop: '🚫',
    camera: '📷',
    park: '🅿️',
    pointer: '👉',
    selfie: '🤳',
    tent: '⛺️',
    toilet: '🚽',
    bee: '🐝'
  }

  return (
    <div
      className={cn('pledge-game', {
        'pledge-game--endgame': content.endgame
      })}
    >
      <Level>
        <EmojiCloud language={language} emojisVisible={content.emoji} />
        <h1
          className='text-center'
          data-firstemoji={
            content.emoji[0] ? `${emojis[content.emoji[0]]} ` : ''
          }
          data-lastemoji={
            content.emoji[1] ? ` ${emojis[content?.emoji[1]]}` : ''
          }
        >
          {content.heading[language]}
        </h1>
        {content.text && <p>{content.text[language]}</p>}
      </Level>
    </div>
  )
}

export default Game
