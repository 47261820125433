import { StepType } from '@types'

export const steps: StepType[] = [
  {
    heading: {
      en: 'The Icelandic Pledge',
      de: 'Der Isländische Schwur',
      es: 'El compromiso Islandés',
      fr: 'L’engagement islandais'
    },
    text: {
      en:
        'BE A RESPONSIBLE TOURIST AND TAKE THE ICELANDIC PLEDGE. ENCOURAGE YOUR FRIENDS TO DO THE SAME.',
      de:
        'Sei ein verantwortungsvoller Tourist und leiste den Isländischen Schwur. Bete deine Freunde, es auch zu tun',
      es:
        'SEA UN TURISTA RESPONSABLE Y HAGA EL COMPROMISO ISLANDÉS. INVITE A SUS AMIGOS A HACERLO TAMBIÉN.',
      fr:
        'Soyez un touriste responsable et participez à l’engagement islandais. encouragez vos amis à en faire autant.'
    },
    largerText: true,
    emoji: [],
    startgame: true
  },
  {
    heading: {
      en: 'I PLEDGE TO BE A RESPONSIBLE TOURIST',
      de: 'Ich schwöre, ein verantortungsvoller Tourist zu sein',
      es: 'ME COMPROMETO A SER UN TURISTA RESPONSABLE',
      fr: 'Je m’engage à être un touriste responsable'
    },
    emoji: ['hand'],
    emojiVariation: 'pledge-emoji--one'
  },
  {
    heading: {
      en: 'WHEN I EXPLORE NEW PLACES, I WILL LEAVE THEM AS I FOUND THEM',
      de:
        'Wenn Ich neue Orte erkunde, hinterlasse Ich sie so, wie Ich sie vorgefunden habe',
      es: 'CUANDO EXPLORE SITIOS NUEVOS, LOS DEJARÉ COMO ESTABAN AL LLEGAR',
      fr: 'Je préserverai les nouveaux endroits que je découvrirai'
    },
    emoji: ['leaf', 'branch', 'bee'],
    emojiVariation: 'pledge-emoji--two'
  },
  {
    heading: {
      en: 'I will take photos to die for, without dying for them',
      de: 'Ich werde zum Sterben schöne Fotos machen, ohne dafür zu sterben',
      es: 'HARÉ FOTOS DE MUERTE SIN ARRIESGAR MI VIDA POR ELLO',
      fr:
        'Je prendrai des photos à tomber, sans risquer de tomber pour les faire'
    },
    emoji: ['camera', 'selfie'],
    emojiVariation: 'pledge-emoji--three'
  },
  {
    heading: {
      en:
        'I will follow the road into the unknown, but never venture off the road',
      de:
        'Ich werde der strasse ins unbekannte folgen aber niemals von der Strasse abweichen',
      es:
        'IRÉ POR LA CARRETERA HACIA LO DESCONOCIDO, PERO NUNCA ME AVENTURARÉ FUERA DE ELL',
      fr:
        'Je suivrai les routes qui mènent vers l’inconnu, sans jamais m’en écarter'
    },
    emoji: ['stop', 'caddie'],
    emojiVariation: 'pledge-emoji--four',
    longtext: true
  },
  {
    heading: {
      en: 'And I will only park where I am supposed to',
      de: 'Und Ich werde nur dort parken, wo es erlaubt ist',
      es: 'Y SOLO APARCARÉ DONDE PUEDA HACERLO',
      fr: 'Je laisserai ma voiture dans les endroits prévus à cet effet'
    },
    emoji: ['park'],
    longtext: true,
    emojiVariation: 'pledge-emoji--three'
  },
  {
    heading: {
      en: 'When I sleep out under the stars, I will stay within a campsite',
      de:
        'Wenn Ich under dem Sternenzelt schlafe, bleibe Ich auf dem Campingplatz',
      es: 'SI DUERMO BAJO EL CIELO ESTRELLADO, LO HARÉ EN UN LUGAR DE ACAMPADA',
      fr: 'J’irai dans un camping pour dormir à la belle étoile'
    },
    emoji: ['tent'],
    emojiVariation: 'pledge-emoji--two',
    longtext: true
  },
  {
    heading: {
      en: 'And when nature calls, I will not answer the call on nature',
      de:
        'Und wenn mich der Ruf der Natur ereilt, beantworte Ich ihn nicht in der Natur',
      es:
        'Y SI LA NATURALEZA ME LLAMA, NO ENVIARÉ MI RESPUESTA A LA NATURALEZA',
      fr: 'Je ne répondrai pas à l’appel de la nature dans la nature'
    },
    emoji: ['toilet'],
    emojiVariation: 'pledge-emoji--four',
    longtext: true
  },
  {
    heading: {
      en: 'Congratulations and enjoy Iceland',
      de: 'Glückwunsch und viel Spaß bei deiner Islandreise',
      es: 'Enhorabuena y disfruta de Islandia',
      fr: 'Félicitations et bon séjour en islande'
    },
    endgame: true,
    emoji: []
  }
]
