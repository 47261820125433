import React from 'react'
import Cloud from '@cmp/site/Cloud'
import Img from 'gatsby-image'
import { ImageAssetType, MediaAssetType } from '@types'
import cn from 'classnames'
import { usePledge } from '@store'
import { FilmGrainImage } from '@islandsstofa/react'

type Props = {
  images: ImageAssetType[]
  video: MediaAssetType
}

const PledgeBackground = ({ images, video }: Props) => {
  const cloud = images.find(image => image.name === 'cloud')
  const tourist = images.find(image => image.name === 'tourist-lady')
  const nightTime = images.find(image => image.name === 'nightTime')
  const mountain = images.find(image => image.name === 'tourist-mountain')

  const currentStep = usePledge(state => state.currentStep)
  const steps = usePledge(state => state.steps)

  const lastStep = currentStep === steps.length - 1

  return (
    <div className='pledge-background position-relative'>
      <video
        muted
        loop
        autoPlay
        playsInline
        className='pledge-background__video'
        src={video.url}
      ></video>

      {cloud && (
        <Cloud
          cloud={cloud}
          className={cn('pledge-background__cloud--farther w-100', {
            'pledge-background__cloud--farther--moveDown': lastStep
          })}
        />
      )}

      {mountain && (
        <div
          className={cn('pledge-background__mountain', {
            'pledge-background__mountain--leave': lastStep
          })}
        >
          <img src={mountain.publicURL} className='w-100' />
        </div>
      )}

      {tourist && (
        <div
          className={cn('pledge-background__tourist', {
            'pledge-background__tourist--leave': lastStep
          })}
        >
          <Img fluid={tourist.fluid} className='w-100' />
        </div>
      )}

      {cloud && (
        <Cloud
          cloud={cloud}
          className={cn('pledge-background__cloud w-100', {
            'pledge-background__cloud--moveDown': lastStep
          })}
        />
      )}

      {nightTime && currentStep === steps.length - 1 && (
        <FilmGrainImage
          image={{ alt: '', url: nightTime.publicURL }}
          canvasId='testing'
          className='pledge-film-grain'
        />
      )}
    </div>
  )
}

export default PledgeBackground
