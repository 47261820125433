import React, { useEffect, useState } from 'react'
import Emoji from '../Emoji'
import cn from 'classnames'
import { usePledge } from '@store'

const Pointer = () => {
  const IDLE_TIME = 7
  const [visible, setVisible] = useState(false)

  console.log('re rendering pointer')

  const currentStep = usePledge(state => state.currentStep)

  useEffect(() => {
    let tick = 0
    setVisible(false)
    const intervalId = setInterval(() => {
      tick = tick + 1
      if (tick === IDLE_TIME) {
        setVisible(true)
        return () => clearInterval(intervalId)
      }
    }, 1000)
    return () => clearInterval(intervalId)
  }, [currentStep])

  return (
    <Emoji
      type='pointer'
      className={cn('pledge-pointer', {
        'pledge-pointer--visible': visible
      })}
    />
  )
}

export default Pointer
