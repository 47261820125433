import { EmojiType, Languages } from '@types'
import React from 'react'
import Emoji from '../Emoji'

type Props = {
  emojisVisible: EmojiType[]
  language: Languages
}

const translatedEmojis = {
  en: [''],
  de: ['camera', 'stop', 'park', 'tent', 'toilet'],
  es: ['hand', 'stop', 'leaf', 'branch', 'tent', 'toilet'],
  fr: [
    'hand',
    'leaf',
    'branch',
    'camera',
    'selfie',
    'caddie',
    'park',
    'tent',
    'toilet'
  ]
}

const EmojiCloud = ({ language, emojisVisible }: Props) => {
  return (
    <div className='emoji-cloud'>
      {emojisVisible.map((type, key) => (
        <Emoji
          className={
            translatedEmojis[language].includes(type)
              ? `pledge-emoji--${type}--${language}`
              : `pledge-emoji--${type}`
          }
          key={key}
          type={type}
        />
      ))}
    </div>
  )
}

export default EmojiCloud
