import React from 'react'
import Img from 'gatsby-image'
import { ImageAssetType, Languages } from '@types'

type Props = {
  images: ImageAssetType[]
  text: string
  language: Languages
}

const LoadingState = ({ images, text, language }: Props) => {
  const cloud = images.find(image => image.name === 'cloud')

  return (
    <div className='pledge-loading-state d-flex justify-content-center align-items-center'>
      <h1 className={`pledge-loading-state--text--${language}`}>{text}</h1>
      {cloud && (
        <div className='h-75 w-100 position-absolute'>
          <Img className='h-100 w-100' fluid={cloud.fluid} />
        </div>
      )}
    </div>
  )
}

export default LoadingState
