import React, { useState, useEffect } from 'react'
import { SpeakerMuted, SpeakerOn } from './Icon'

const Speaker = () => {
  const [muted, setMuted] = useState(false)
  const buttonClass = 'pledge-speaker__button'

  useEffect(() => {
    const audio = document.querySelectorAll('audio')
    audio.forEach(audio => (audio.muted = muted))
  }, [muted])

  return (
    <div className='container pledge-speaker'>
      {muted ? (
        <button className={buttonClass} onClick={() => setMuted(false)}>
          <SpeakerMuted />
        </button>
      ) : (
        <button className={buttonClass} onClick={() => setMuted(true)}>
          <SpeakerOn />
        </button>
      )}
    </div>
  )
}

export default Speaker
