import React from 'react'
import Canvas from './Canvas'
import Button from './Button'

type Props = {
  text: string
}

const CircleButton = ({ text }: Props) => {
  return (
    <div className='position-relative pledge-circle-button mt-0'>
      <Canvas text={text} />
      <Button />
    </div>
  )
}

export default CircleButton
