import { graphql, useStaticQuery } from 'gatsby'
import { ImageAssetType, MediaAssetType } from '@types'

const useGetAssets = () => {
  const result = useStaticQuery(graphql`
    query pledgeAssets {
      images: allFile(filter: { absolutePath: { regex: "/pledge/images/" } }) {
        nodes {
          name
          publicURL
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      video: file(absolutePath: { regex: "/pledge/video/" }) {
        publicURL
        name
      }
      sounds: allFile(filter: { absolutePath: { regex: "/pledge/sounds/" } }) {
        nodes {
          publicURL
          name
        }
      }
    }
  `)

  const images: ImageAssetType[] = result.images.nodes.map((node: any) => ({
    name: node.name,
    fluid: node.childImageSharp.fluid,
    publicURL: node.publicURL
  }))
  const video: MediaAssetType = {
    url: result.video.publicURL,
    name: result.video.name
  }
  const sounds: MediaAssetType[] = result.sounds.nodes.map((node: any) => ({
    name: node.name,
    url: node.publicURL
  }))

  return { images, video, sounds }
}

export { useGetAssets }
