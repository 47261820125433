import React from 'react'

const URL =
  'https://my.spline.design/badgeringcopy-b87007bf39f5fe9f03c02062ef49e02a/'

const Badge = () => {
  return (
    <div className='pledge-badge d-flex justify-content-center'>
      <iframe src={URL} frameBorder='0' width='100%' height='100%'></iframe>
    </div>
  )
}

export default Badge
